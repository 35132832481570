import React from 'react';
import './Header.css'; // Import the CSS file

const Header = () => {
  const headerStyle = {
    backgroundColor: 'rgb(0, 31, 61)', // Matches `.bg-[#001f3d]`
    color: 'white',
    padding: '1rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Ensures space between logo and "Get Started"
    paddingLeft: '2rem',
    paddingRight: '2rem',
    borderBottom: '2px solid white', // Adds a white margin line below the header
  };

  const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem', // Matches `space-x-3`
  };

  const logoStyle = {
    width: '2.5rem', // Matches `w-10`
    height: '2.5rem', // Matches `h-10`
    border: '2px solid rgb(255 255 255 / 1)', // Matches `border-2` and `border-white`
    borderRadius: '0.5rem', // Matches `rounded-lg`
    backgroundColor: 'rgb(255 255 255 / 1)', // Matches `bg-white`
    objectFit: 'cover', // Matches `object-cover`
  };

  const textStyle = {
    // color: 'rgb(255 255 255 / 1)', 
    fontWeight: 700, // Matches `font-bold`
    fontSize: '1.5rem', // Matches `text-2xl`
    lineHeight: '2rem', // Matches `text-2xl` line-height
    fontFamily: "'Cormorant Garamond', serif", // Matches `font-serif`
    margin: 0,
  };

  const linkStyle = {
    position: 'relative', // Matches `relative`
    color: 'inherit', // Keeps text color consistent
    textDecoration: 'inherit', // Removes underline or other decorations
    fontWeight: 500, // Matches `font-medium`
    cursor: 'pointer', // Pointer cursor on hover
  };

  return (
    <header style={headerStyle}>
      <div style={logoContainerStyle}>
        {/* Logo */}
        <img
          src="https://winlooks.netlify.app/assets/logo-BD_ZnXDL.jpg"
          alt="WINLOOKS Logo"
          style={logoStyle}
        />
        {/* Styled Text */}
        <h1 style={textStyle}>WINLOOKS</h1>
      </div>
      {/* Get Started Link */}
      <a 
        href="https://forms.gle/Vs2Lc8c5iCy43MGU6" 
        className="get-started" 
        style={linkStyle} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Get Started
      </a>
    </header>
  );
};

export default Header;