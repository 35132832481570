import React from 'react';

const Footer = () => {
  const footerStyle = {
    backgroundColor: 'white', // Set background color to white
    color: 'black', // Set text color to black
    padding: '3rem 2rem',
    display: 'grid',
    gap: '2rem',
  };

  const footerContentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '2rem',
    flexWrap: 'wrap', // Wrap content for smaller screens
  };

  const logoSectionStyle = {
    flex: '1 1 100%', // Full width on smaller screens
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };

  const headingStyle = {
    fontWeight: 'bold',
    marginBottom: '1rem',
    fontSize: '1.25rem',
  };

  const contactInfoStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    fontSize: '0.875rem',
  };

  const socialIconsStyle = {
    display: 'flex',
    gap: '0.75rem', // Space between social icons
    fontSize: '1.5rem',
  };

  const largeScreenStyles = {
    footer: {
      padding: '3rem 16rem', // Increase padding for larger screens
    },
    footerContent: {
      gap: '4rem', // Adjust gap for larger screens
    },
    logoSection: {
      flex: '1 1 auto',
    },
  };

  return (
    <footer
      style={{
        ...footerStyle,
        ...(window.innerWidth >= 768 && largeScreenStyles.footer), // Apply large screen styles
      }}
    >
      <div
        style={{
          ...footerContentStyle,
          ...(window.innerWidth >= 768 && largeScreenStyles.footerContent),
        }}
      >
        {/* Logo Section */}
        <div
          style={{
            ...logoSectionStyle,
            ...(window.innerWidth >= 768 && largeScreenStyles.logoSection),
          }}
        >
          <h2 style={{ fontFamily: 'Cormorant Garamond, serif', fontSize: '1.5rem', fontWeight: 'bold' }}>
            WINLOOKS
          </h2>
          <p style={{ fontSize: '0.875rem', color: 'black' }}>
            Where Style Meets Perfection
          </p>
          <p style={{ marginTop: '0.5rem', fontSize: '0.875rem', color: 'black' }}>
            Premium fashion design consultancy for the discerning client.
          </p>
        </div>

        {/* Contact Us Section */}
        <div>
          <h3 style={headingStyle}>Contact Us</h3>
          <div style={contactInfoStyle}>
            <p>
              Email:{' '}
              <a href="mailto:winlooks001@gmail.com" style={{ color: 'black', textDecoration: 'none' }}>
                winlooks001@gmail.com
              </a>
            </p>
            <p>
              Phone:{' '}
              <a href="tel:+917795736047" style={{ color: 'black', textDecoration: 'none' }}>
                +91 7795736047
              </a>
            </p>
          </div>
        </div>

        {/* Social Media Section */}
        <div>
          <h3 style={headingStyle}>Follow Us</h3>
          <div style={socialIconsStyle}>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black' }}
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black' }}
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
