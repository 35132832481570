import React from "react";
import './Hero.css'; // Import the updated CSS file

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <h1>Stressed about  what to wear ?</h1>
        <h2>Don’t worry, we got you covered!</h2>
        <a
          href="https://forms.gle/Vs2Lc8c5iCy43MGU6"
          className="hero-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a Demo
        </a>
      </div>
    </div>
  );
};

export default Hero;
