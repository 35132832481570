import React from 'react';

const MeetOurDesigners = () => {
  const containerStyle = {
    backgroundColor: 'white', // Keep background white for contrast
    color: 'black', // Default text color (change individual elements to white where needed)
    padding: '2rem 1rem',
    textAlign: 'center',
  };

  const designersContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap', // Ensure designers wrap on smaller screens
    justifyContent: 'center',
    gap: '1rem',
  };

  const designerStyle = {
    backgroundColor: '#293250', // Card background color
    borderRadius: '8px',
    padding: '1rem',
    textAlign: 'center',
    margin: '1rem',
    width: '300px',
    maxWidth: '90%', // Responsive width for small screens
    boxSizing: 'border-box',
    cursor: 'pointer', // Add cursor pointer to indicate clickable items
    transition: 'transform 0.2s ease-in-out', // Add hover effect
  };

  const imageStyle = {
    width: '100%',
    height: '300px', // Set fixed height for uniformity
    objectFit: 'cover',
    borderRadius: '8px',
    marginBottom: '1rem',
  };

  const titleStyle = {
    color: 'white', // Set title text color to white
    fontWeight: 'bold',
    fontSize: '1.25rem',
    marginBottom: '0.5rem',
  };

  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  const designers = [
    {
      name: 'Book a Demo',
      img: '/book1..jpg', // Replace with the actual path of your image in the `public` folder
      action: () => handleClick('https://forms.gle/Vs2Lc8c5iCy43MGU6'),
    },
    {
      name: 'Get connected with your fashion designer',
      img: '/book2.jpg', // Replace with the actual path of your image in the `public` folder
      action: () => handleClick('https://forms.gle/Vs2Lc8c5iCy43MGU6'),
    },
    {
      name: 'Transform your look',
      img: '/image1.jpg', // Replace with the actual path of your image in the `public` folder
    },
  ];

  return (
    <section style={containerStyle}>
      <h2
        style={{
          color: 'black', // Keep section title in black for contrast with the background
          fontWeight: '700',
          fontSize: '2rem',
          fontFamily: 'Cormorant Garamond, serif',
          marginBottom: '2rem',
        }}
      >
        It's Simple
      </h2>
      <div style={designersContainerStyle}>
        {designers.map((designer, index) => (
          <div
            key={index}
            style={designerStyle}
            onClick={designer.action} // Attach click handler here
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')} // Hover effect
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            <img src={designer.img} alt={designer.name} style={imageStyle} />
            <h3 style={titleStyle}>{designer.name}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MeetOurDesigners;