import React from 'react';

const LaunchingSoon = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'white', // Set background color to white
    color: 'black', // Set text color to black
    textAlign: 'center',
  };

  const textStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
    fontFamily: 'Cormorant Garamond, serif',
  };

  return (
    <div style={containerStyle}>
      <p style={textStyle}>Launching Soon...</p>
    </div>
  );
};

export default LaunchingSoon;
