import React from 'react';
import { useNavigate } from 'react-router-dom';

const HowItWorks = () => {
  const navigate = useNavigate();

  const containerStyle = {
    // backgroundColor: 'rgb(26, 26, 46)',
    color: 'white',
    padding: '2rem 1rem',
    textAlign: 'center',
  };

  const stepsContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap', // Ensure steps wrap on smaller screens
    justifyContent: 'center',
    gap: '1rem',
  };

  const stepStyle = {
    backgroundColor: 'rgb(41, 50, 80)',
    borderRadius: '8px',
    padding: '1rem',
    margin: '1rem',
    width: '300px',
    maxWidth: '90%', // Responsive width for small screens
    boxSizing: 'border-box',
    textAlign: 'center',
    cursor: 'pointer', // Indicate clickable
  };

  const imageStyle = {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginBottom: '1rem',
  };

  const steps = [
    {
      title: 'Personalized Fashion Recommendation',
      img: '/sevices2.png',
      action: () => window.open('https://forms.gle/Vs2Lc8c5iCy43MGU6', '_blank'),
    },
    {
      title: 'Tailoring Service',
      img: '/tailoring.jpg',
      action: () => navigate('/launching-soon'),
    },
    {
      title: 'Virtual Try-on',
      img: '/personalised.jpg',
      action: () => navigate('/launching-soon'),
    },
  ];

  return (
    <section style={containerStyle}>
      <h2
        style={{
          fontWeight: '700',
          fontSize: '2rem',
          fontFamily: 'Cormorant Garamond, serif',
          marginBottom: '2rem',
          color: 'black',
          
        }}
      >
        Services Offered
      </h2>
      <div style={stepsContainerStyle}>
        {steps.map((step, index) => (
          <div
            key={index}
            style={stepStyle}
            onClick={step.action} // Add click handler
          >
            <img src={step.img} alt={step.title} style={imageStyle} />
            <h3 style={{ fontWeight: 'bold', fontSize: '1.25rem', marginBottom: '1rem' }}>
              {step.title}
            </h3>
            <p style={{ fontSize: '1rem', lineHeight: '1.5rem' }}>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
