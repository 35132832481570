import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Gallery from './components/Gallery';
import HowItWorks from './components/HowItWorks';
import MeetOurDesigners from './components/MeetOurDesigners';
import Footer from './components/Footer';
import LaunchingSoon from './components/LaunchingSoon';

const App = () => {
  return (
    <Router>
      <Header />
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  const location = useLocation();

  // Define routes where the footer should be hidden
  const noFooterRoutes = ['/launching-soon'];

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Gallery />
              <HowItWorks />
              <MeetOurDesigners />
            </>
          }
        />
        <Route path="/launching-soon" element={<LaunchingSoon />} />
      </Routes>
      {/* Conditionally render Footer */}
      {!noFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
};

export default App;
