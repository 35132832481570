import React from 'react';

const Gallery = () => {
  const galleryStyle = {
    backgroundColor: 'white', // Change background color to white
    paddingTop: '2rem',
    paddingBottom: '2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '1.5rem',
  };

  const cardStyle = {
    backgroundColor: '#293250', // Lighter background for the card
    borderRadius: '8px',
    padding: '1rem',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const imageStyle = {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '8px',
  };

  const imageStyle1 = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  };

  return (
    <section style={galleryStyle}>
      <h2
        style={{
          textAlign: 'center',
          color: 'black', // Change text color to black
          fontWeight: 700,
          fontSize: '2.25rem',
          lineHeight: '2.5rem',
          fontFamily: 'Cormorant Garamond, serif',
          marginBottom: '3rem',
        }}
      >
        Fashion Redefined: Before vs. After
      </h2>
      <div style={gridStyle}>
        <div style={cardStyle}>
          <img src="/image1.jpg" alt="Fashion Look 1" style={imageStyle} />
        </div>
        <div style={cardStyle}>
          <img src="/image2.jpg" alt="Fashion Look 2" style={imageStyle1} />
        </div>
        <div style={cardStyle}>
          <img src="/img3.jpg" alt="Fashion Look 3" style={imageStyle} />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
